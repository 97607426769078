import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';
import { isNil } from '~utils';

/**
 * get all tasks
 * @param {String, Number} clientId
 * @param {Boolean} ofToday
 */
export const getTasks = async (parentTaskId = -1, filter = {}) => {
	try {
		const {
			data: { GetTasks }
		} = await apollo.query({
			query: gql`
				query ($ParentTaskId: ID!, $Filter: TaskFilterInput) {
					GetTasks(ParentTaskId: $ParentTaskId, Filter: $Filter) {
						id
						xid
						name
						description
						ownerId
						parentTaskId
						taskTypeId
						status
						priority
						duration
						clientId
						dueDate
						createdAt
						images
						start
						end
						dayDuration
						desiredStart
						putInPlanning
						taskPlanningId
						taskParentName
						taskParentRootName
						rootId
						rootXid
						percentCompleted
						locationId
						addressId
						offerDate
						percentDiscount
						percentDeposit
						vat
						totalBudget
						totalBudgetTtc
						sendStatus
						syndicId
						architectId
						invoiceId
						taskConstraint {
							id
							taskId
							waitForTaskId
						}
						type {
							id
							name
							picture
						}
						owner {
							id
							email
							name
							firstName
						}
						client {
							id
							name
							email
							phone
							address {
								id
								state
								street
								number
								box
								lat
								lng
								city
								country
								countryCode
								countryKey
								zip
							}
						}
						assignedTo {
							id
							name
							firstName
							fullName
							picture
							departmentName
							functionName
							color
						}
						assignedTeam {
							id
							name
							description
							color
							active
							members {
								id
								name
								firstName
								fullName
								picture
								departmentName
								functionName
								color
							}
						}
						items {
							id
							taskId
							taskTypeItemId
							name
							unit
							unitPrice
							customUnit
							quantity
							unitPrice
							totalPrice
						}
						location {
							id
							clientId
							setName
							type
							selected
							description
							note
							setAddress {
								id
								state
								street
								number
								box
								lat
								lng
								city
								country
								countryCode
								countryKey
								zip
							}
						}
						address {
							id
							state
							street
							number
							box
							lat
							lng
							city
							country
							countryCode
							countryKey
							zip
						}
						invoice {
							addressId
							bic
							companyType
							contactFunction
							contactName
							email
							iban
							id
							info
							licenseId
							name
							nationalRegistryNumber
							phone
							type
							vat
							address {
								id
								state
								street
								number
								box
								lat
								lng
								city
								country
								countryCode
								countryKey
								zip
							}
						}
						offerNumber
						orderNumber
						offerDocTemplateXid
						prtPageBreak
						prtLnDescriptionSection
						prtHideDetails
						internalPriceFactor
					}
				}
			`,
			variables: {
				ParentTaskId: parseInt(parentTaskId, 10),
				Filter: filter
			},
			fetchPolicy: 'no-cache'
		});

		return GetTasks;
	} catch (e) {
		console.log({ e });
	}
};

/** */
export const getTask = async (taskPlanningId = null, taskId = null) => {
	try {
		const {
			data: { GetTask }
		} = await apollo.query({
			query: gql`
				query ($TaskPlanningId: ID, $TaskId: ID) {
					GetTask(TaskPlanningId: $TaskPlanningId, TaskId: $TaskId) {
						id
						xid
						name
						description
						ownerId
						parentTaskId
						taskTypeId
						status
						priority
						duration
						clientId
						dueDate
						createdAt
						images
						start
						end
						dayDuration
						desiredStart
						putInPlanning
						taskPlanningId
						taskParentName
						rootId
						rootXid
						percentCompleted
						addressId
						locationId
						offerDate
						percentDiscount
						percentDeposit
						vat
						totalBudget
						totalBudgetTtc
						sendStatus
						syndicId
						architectId
						invoiceId
						type {
							id
							name
							picture
						}
						owner {
							id
							email
							name
							firstName
						}
						client {
							id
							name
							email
							phone
						}
						assignedTo {
							id
							name
							firstName
							fullName
							picture
							departmentName
							functionName
						}
						assignedTeam {
							id
							name
							description
							color
							active
							members {
								id
								name
								firstName
								fullName
								picture
								departmentName
								functionName
							}
						}
						items {
							id
							taskId
							taskTypeItemId
							name
							unit
							unitPrice
							customUnit
							quantity
							unitPrice
							totalPrice
						}
						invoice {
							addressId
							bic
							companyType
							contactFunction
							contactName
							email
							iban
							id
							info
							licenseId
							name
							nationalRegistryNumber
							phone
							type
							vat
							address {
								id
								state
								street
								number
								box
								lat
								lng
								city
								country
								countryCode
								countryKey
								zip
							}
						}
						offerNumber
						orderNumber
						offerDocTemplateXid
						prtPageBreak
						prtLnDescriptionSection
						prtHideDetails
						internalPriceFactor
					}
				}
			`,
			variables: {
				TaskPlanningId: !isNil(taskPlanningId) ? parseInt(taskPlanningId, 10) : taskPlanningId,
				TaskId: !isNil(taskId) ? parseInt(taskId, 10) : taskId
			},
			fetchPolicy: 'no-cache'
		});

		return GetTask;
	} catch (e) {
		console.log({ e });
	}
};

/**
 * @param {String|Number} taskPlanningId
 */
export const delTask = async (taskPlanningId, taskId) => {
	// if (isNil(taskPlanningId)) return
	try {
		const mutation = gql`
			mutation ($TaskPlanningId: ID!, $TaskId: ID!) {
				DelTask(TaskPlanningId: $TaskPlanningId, TaskId: $TaskId)
			}
		`;
		const { data } = await apollo.mutate({
			mutation,
			variables: {
				TaskPlanningId: parseInt(taskPlanningId, 10),
				TaskId: parseInt(taskId, 10)
			}
		});

		return data.DelTask;
	} catch (e) {
		console.error({ e });
	}
};

/**
 * @param {String|Number} taskId
 */
export const delMainTask = async (taskId) => {
	if (isNil(taskId)) return;
	try {
		const mutation = gql`
			mutation ($TaskId: ID!) {
				DelMainTask(TaskId: $TaskId)
			}
		`;
		const { data } = await apollo.mutate({
			mutation,
			variables: {
				TaskId: parseInt(taskId, 10)
			}
		});

		return data.DelMainTask;
	} catch (e) {
		console.error({ e });
	}
};
export const updatedTask = async (taskId, updatedTask) => {
	if (isNil(taskId)) return;
	try {
		const mutation = gql`
			mutation ($TaskId: ID!, $UpdatedTask: TaskInput!) {
				AddUpdTask(TaskId: $TaskId, UpdatedTask: $UpdatedTask) {
					id
					taskPlanningId
					addressId
					locationId
					clientId
				}
			}
		`;
		const { data } = await apollo.mutate({
			mutation,
			variables: {
				TaskId: parseInt(taskId, 10),
				UpdatedTask: updatedTask
			}
		});

		return data.AddUpdTask;
	} catch (e) {
		console.error({ e });
	}
};

export const addTaskResourceUser = async (taskPlanningId, userId) => {
	if (isNil(taskPlanningId) && isNil(userId)) return;
	try {
		const mutation = gql`
      mutation ($TaskPlanningId: ID!, $UserId: ID!) {
        AddTaskResourceUser(
          TaskPlanningId: $TaskPlanningId
          UserId: $UserId
        ) ${mutationResTask}
      }
    `;
		const { data } = await apollo.mutate({
			mutation,
			variables: {
				TaskPlanningId: parseInt(taskPlanningId, 10),
				UserId: parseInt(userId, 10)
			}
		});

		return data.AddTaskResourceUser;
	} catch (e) {
		console.error({ e });
	}
};

export const delTaskResourceUser = async (taskPlanningId, userId) => {
	if (isNil(taskPlanningId) && isNil(userId)) return;
	try {
		const mutation = gql`
			mutation ($TaskPlanningId: ID!, $UserId: ID!) {
				DelTaskResourceUser(TaskPlanningId: $TaskPlanningId, UserId: $UserId)
			}
		`;
		const { data } = await apollo.mutate({
			mutation,
			variables: {
				TaskPlanningId: parseInt(taskPlanningId, 10),
				UserId: parseInt(userId, 10)
			}
		});

		return data.DelTaskResourceUser;
	} catch (e) {
		console.error({ e });
	}
};

export const getTaskResourceUsers = async (taskPlanningId) => {
	if (isNil(taskPlanningId)) return;
	try {
		const {
			data: { GetTaskResourceUsers }
		} = await apollo.query({
			query: gql`
				query ($TaskPlanningId: ID!) {
					GetTaskResourceUsers(TaskPlanningId: $TaskPlanningId) {
						id
						firstName
						name
						fullName
						email
						picture
						department
						departmentName
						function
						functionName
					}
				}
			`,
			variables: {
				TaskPlanningId: parseInt(taskPlanningId, 10)
			},
			fetchPolicy: 'no-cache'
		});

		return GetTaskResourceUsers;
	} catch (e) {
		console.error({ e });
	}
};

export const addTaskResourceTeam = async (taskPlanningId, teamId) => {
	if (isNil(taskPlanningId) && isNil(teamId)) return;
	try {
		const mutation = gql`
      mutation ($TaskPlanningId: ID!, $TeamId: ID!) {
        AddTaskResourceTeam(
          TaskPlanningId: $TaskPlanningId
          TeamId: $TeamId
        ) ${mutationResTask}
      }
    `;
		const { data } = await apollo.mutate({
			mutation,
			variables: {
				TaskPlanningId: parseInt(taskPlanningId, 10),
				TeamId: parseInt(teamId, 10)
			}
		});

		return data.AddTaskResourceTeam;
	} catch (e) {
		console.error({ e });
	}
};

export const delTaskResourceTeam = async (taskPlanningId, teamId) => {
	if (isNil(taskPlanningId) && isNil(teamId)) return;
	try {
		const mutation = gql`
			mutation ($TaskPlanningId: ID!, $TeamId: ID!) {
				DelTaskResourceTeam(TaskPlanningId: $TaskPlanningId, TeamId: $TeamId)
			}
		`;
		const { data } = await apollo.mutate({
			mutation,
			variables: {
				TaskPlanningId: parseInt(taskPlanningId, 10),
				TeamId: parseInt(teamId, 10)
			}
		});

		return data.DelTaskResourceTeam;
	} catch (e) {
		console.error({ e });
	}
};

export const getTaskResourceTeams = async (taskPlanningId) => {
	if (isNil(taskPlanningId)) return;
	try {
		const {
			data: { GetTaskResourceTeams }
		} = await apollo.query({
			query: gql`
				query ($TaskPlanningId: ID!) {
					GetTaskResourceTeams(TaskPlanningId: $TaskPlanningId) {
						id
						name
						description
						color
						active
						members {
							id
							name
							firstName
							email
							picture
							department
							departmentName
							function
							functionName
						}
					}
				}
			`,
			variables: {
				TaskPlanningId: parseInt(taskPlanningId, 10)
			},
			fetchPolicy: 'no-cache'
		});

		return GetTaskResourceTeams;
	} catch (e) {
		console.error({ e });
	}
};

export const updatedTaskItem = async (taskItemId, updatedTaskItem) => {
	if (isNil(taskItemId)) return;
	try {
		const mutation = gql`
			mutation ($TaskItemId: ID!, $UpdTaskItem: TaskItemInput!) {
				AddUpdTaskItem(TaskItemId: $TaskItemId, UpdTaskItem: $UpdTaskItem) {
					id
					taskId
					createdAt
					name
					unit
					customUnit
					quantity
					unitPrice
					totalPrice
				}
			}
		`;
		const { data } = await apollo.mutate({
			mutation,
			variables: {
				TaskItemId: parseInt(taskItemId, 10),
				UpdTaskItem: updatedTaskItem
			}
		});

		return data.AddUpdTaskItem;
	} catch (e) {
		console.error({ e });
	}
};

/**
 * @param {String|Number} taskItemId
 */
export const delTaskItem = async (taskItemId) => {
	if (isNil(taskItemId)) return;
	try {
		const mutation = gql`
			mutation ($TaskItemId: ID!) {
				DelTaskItem(TaskItemId: $TaskItemId)
			}
		`;
		const { data } = await apollo.mutate({
			mutation,
			variables: {
				TaskItemId: parseInt(taskItemId, 10)
			}
		});

		return data.DelTaskItem;
	} catch (e) {
		console.error({ e });
	}
};

/**
 * get all tasks
 * @param {String, Number} taskId
 */
export const getTaskItems = async (taskId) => {
	try {
		const {
			data: { GetTaskItems }
		} = await apollo.query({
			query: gql`
				query ($TaskId: ID!) {
					GetTaskItems(TaskId: $TaskId) {
						id
						taskId
						createdAt
						name
						unit
						customUnit
						quantity
						unitPrice
						totalPrice
					}
				}
			`,
			variables: {
				TaskId: parseInt(taskId, 10)
			},
			fetchPolicy: 'no-cache'
		});

		return GetTaskItems;
	} catch (e) {
		console.log({ e });
	}
};

export const getTaskItem = async (taskItemId) => {
	try {
		const {
			data: { GetTaskItem }
		} = await apollo.query({
			query: gql`
				query ($TaskItemId: ID!) {
					GetTaskItem(TaskItemId: $TaskItemId) {
						id
						taskId
						createdAt
						name
						unit
						customUnit
						quantity
						unitPrice
						totalPrice
					}
				}
			`,
			variables: {
				TaskItemId: parseInt(taskItemId, 10)
			},
			fetchPolicy: 'no-cache'
		});

		return GetTaskItem;
	} catch (e) {
		console.log({ e });
	}
};

export const setDesiredStartTask = async (taskId, newDesiredDate) => {
	try {
		const {
			data: { SetFirstTimeDesiredDateTo }
		} = await apollo.mutate({
			mutation: gql`
				mutation SetFirstTimeDesiredDateTo($taskId: ID!, $newDesiredDate: String!) {
					SetFirstTimeDesiredDateTo(TaskId: $taskId, NewDesiredDate: $newDesiredDate)
				}
			`,
			variables: {
				taskId: parseInt(taskId),
				newDesiredDate
			},
			fetchPolicy: 'no-cache'
		});

		return SetFirstTimeDesiredDateTo;
	} catch (e) {
		console.log({ e });
	}
};

export const addTaskConstraint = async (input) => {
	try {
		const {
			data: { AddTaskConstraint }
		} = await apollo.mutate({
			mutation: gql`
				mutation ADD_TASK_CONSTRAINT($taskConstraintInput: TaskConstraintInput!) {
					AddTaskConstraint(NewTaskConstraint: $taskConstraintInput) {
						id
						createdAt
						updatedAt
						taskId
						waitForTaskId
					}
				}
			`,
			variables: {
				taskConstraintInput: input
			},
			fetchPolicy: 'no-cache'
		});

		return AddTaskConstraint;
	} catch (e) {
		console.log({ e });
	}
};

export const delTaskConstraint = async (taskId, waitForTaskId) => {
	try {
		const {
			data: { DelTaskConstraint }
		} = await apollo.mutate({
			mutation: gql`
				mutation DEL_TASK_CONTRAINT($taskId: ID!, $waitForTaskId: ID!) {
					DelTaskConstraint(TaskId: $taskId, WaitForTaskId: $waitForTaskId)
				}
			`,
			variables: {
				taskId: parseInt(taskId),
				waitForTaskId: parseInt(waitForTaskId)
			},
			fetchPolicy: 'no-cache'
		});

		return DelTaskConstraint;
	} catch (e) {
		console.log({ e });
	}
};

export const getTaskAddress = async (taskId) => {
	try {
		const {
			data: { GetTaskAddress }
		} = await apollo.query({
			query: gql`
				query ($taskId: ID!) {
					GetTaskAddress(TaskId: $taskId) {
						id
						state
						street
						number
						box
						lat
						lng
						city
						country
						countryCode
						zip
					}
				}
			`,
			variables: {
				taskId: parseInt(taskId, 10)
			},
			fetchPolicy: 'no-cache'
		});

		return GetTaskAddress;
	} catch (e) {
		console.log({ e });
	}
};

export const getLocation = async (locationId) => {
	try {
		const {
			data: { GetLocation }
		} = await apollo.query({
			query: gql`
				query ($locationId: ID!) {
					GetLocation(LocationId: $locationId) {
						id
						clientId
						setName
						type
						selected
						description
						note
						setAddress {
							id
							state
							street
							number
							box
							lat
							lng
							city
							country
							countryCode
							countryKey
							zip
						}
					}
				}
			`,
			variables: {
				locationId: parseInt(locationId, 10)
			},
			fetchPolicy: 'no-cache'
		});

		return GetLocation;
	} catch (e) {
		console.log({ e });
	}
};

export const acceptedOffer = async (taskParentId) => {
	try {
		const mutation = gql`
			mutation ACCEPTED_OFFER($taskParentId: ID!) {
				AcceptTaskOffer(TaskParentId: $taskParentId)
			}
		`;
		const {
			data: { AcceptTaskOffer }
		} = await apollo.mutate({
			mutation,
			variables: {
				taskParentId: parseInt(taskParentId)
			},
			fetchPolicy: 'no-cache'
		});

		return AcceptTaskOffer;
	} catch (e) {
		console.log(e.message);
	}
};

const mutationResTask = `{
  id
}`;

export const getMainSubTasks = async (parentTaskId) => {
	try {
		const {
			data: { GetSubTasks }
		} = await apollo.query({
			query: gql`
				query ($parentTaskId: ID!) {
					GetSubTasks(ParentTaskId: $parentTaskId) {
						id
						taskPlanningId
						name
						type {
							id
							name
							picture
						}
					}
				}
			`,
			variables: {
				parentTaskId: parseInt(parentTaskId, 10)
			},
			fetchPolicy: 'no-cache'
		});

		return GetSubTasks;
	} catch (e) {
		console.log({ e });
	}
};

export const isProjectLabelFormatEmpty = async () => {
	try {
		const mutation = gql`
			mutation {
				IsProjectLabelFormatEmpty
			}
		`;
		const {
			data: { IsProjectLabelFormatEmpty }
		} = await apollo.mutate({
			mutation
		});

		return IsProjectLabelFormatEmpty;
	} catch (e) {
		console.error({ e });
	}
};

export const createTaskOffer = async (offerId) => {
	const mutation = gql`
		mutation TASK_FOR_OFFER($offerId: ID!) {
			CreateTaskForOffer(OfferId: $offerId)
		}
	`;
	const {
		data: { CreateTaskForOffer }
	} = await apollo.mutate({
		mutation,
		variables: {
			offerId: parseInt(offerId, 10)
		},
		fetchPolicy: 'no-cache'
	});

	return CreateTaskForOffer;
};

export const copyOffer = async (taskId, isVariant = false) => {
	const mutation = gql`
		mutation COPY_OFFER($taskId: ID!, $isVariant: Boolean!) {
			CopyOffer(TaskId: $taskId, IsVariant: $isVariant) {
				id
			}
		}
	`;
	const {
		data: { CopyOffer }
	} = await apollo.mutate({
		mutation,
		variables: {
			taskId: parseInt(taskId, 10),
			isVariant
		},
		fetchPolicy: 'no-cache'
	});

	return CopyOffer;
};

export const isTaskAlreadyCreated = async (offerId) => {
	const mutation = gql`
		mutation IS_TASK_ALREADY_CREATED($offerId: ID!) {
			IsOfferTaskAlreadyAvailable(OfferId: $offerId)
		}
	`;
	const {
		data: { IsOfferTaskAlreadyAvailable }
	} = await apollo.mutate({
		mutation,
		variables: {
			offerId: parseInt(offerId, 10)
		},
		fetchPolicy: 'no-cache'
	});

	return IsOfferTaskAlreadyAvailable;
};

export const getPlannings = async (taskId) => {
	const query = gql`
		query GET_PLANNINGS($taskId: ID!) {
			GetPlannings(TaskId: $taskId) {
				id
				createdAt
				updatedAt
				taskId
				desiredStart
				duration
				start
				end
				users {
					id
					name
					firstName
					fullName
					picture
					departmentName
					functionName
					color
				}
				teams {
					id
					name
					description
					color
					active
					members {
						id
						name
						firstName
						fullName
						picture
						departmentName
						functionName
						color
					}
				}
			}
		}
	`;

	const {
		data: { GetPlannings }
	} = await apollo.query({
		query,
		variables: {
			taskId: parseInt(taskId)
		},
		fetchPolicy: 'no-cache'
	});

	return GetPlannings;
};

export const assigneeMultiple = async (assigneeMultipleInput = null, delAssigneeMultipleInput = null) => {
	let mutation = null;
	let variables = null;
	if (assigneeMultipleInput) {
		mutation = gql`
			mutation ASSIGN_MULTIPLE_USER($assigneeMultiple: [AssigneeMultipleInput]) {
				AssignMultipleUser(AssigneeMultiple: $assigneeMultiple)
			}
		`;
		variables = {
			assigneeMultiple: assigneeMultipleInput
		};
	}
	if (delAssigneeMultipleInput) {
		mutation = gql`
			mutation ASSIGN_MULTIPLE_USER($delAssigneeMultiple: [AssigneeMultipleInput]) {
				AssignMultipleUser(DelAssigneeMultiple: $delAssigneeMultiple)
			}
		`;
		variables = {
			delAssigneeMultiple: delAssigneeMultipleInput
		};
	}

	const {
		data: { AssignMultipleUser }
	} = await apollo.mutate({
		mutation,
		variables,
		fetchPolicy: 'no-cache'
	});

	return AssignMultipleUser;
};

export const replaceAssignee = async (replaceAssigneeInput) => {
	const mutation = gql`
		mutation REPLACE_ASSIGNEE($replaceAssigneeInput: [ReplaceAssigneeInput]!) {
			ReplaceAssignees(ReplaceAssignee: $replaceAssigneeInput)
		}
	`;

	const {
		data: { ReplaceAssignees }
	} = await apollo.mutate({
		mutation,
		variables: {
			replaceAssigneeInput
		},
		fetchPolicy: 'no-cache'
	});

	return ReplaceAssignees;
};
